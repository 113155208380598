@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}


.swiper_container { 
  padding: 2rem 0 !important;
  position: relative;
}

.swiper-slide {
  width: 42% !important;
  /* height: 42rem !important; */
  position: relative; padding: 0 15px;
}
.swiper-slide {
  transition: transform 0.5s, filter 0.5s;
  /* filter: blur(5px); */
  pointer-events: none;
}

.swiper-slide.swiper-slide-active{    filter: inherit;
  pointer-events: inherit;}

@media (max-width: 500px) {
 
 
}

.swiper-slide img {
  width: 100%;
  
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
.swiper-slide-active div {
  /* width: 200px;
  height: 300px; */
  position: relative;
  /* background: #111; */
  padding: 4px;
}
/* .swiper-slide-active div::before,
.swiper-slide-active div::after {
  content: "";
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: linear-gradient(var(--angle), 
    #3ea353,  #62c0ff, #b00);
  animation: rotates 10s linear infinite;    border-radius: 30px;

}
.swiper-slide-active div::after {
  filter: blur(10px);
}
@keyframes rotates {
  0%     { --angle: 0deg; }
  100%   { --angle: 360deg;
  }
} */